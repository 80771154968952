import React from 'react';
import Films from './Films'

function Home() {
    return (
        <div>
            <Films/>
        </div>
    );

}

export default Home;